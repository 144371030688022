'use strict';

require('hc-sticky');

const $siteHeader = $('.siteheader');

/**
 * Destroy sticky components
 */
function destroyStickyPLPComponents() {
    $('.js-filter-bar').hcSticky('destroy');
}

/**
 * Update sticky components on PLP for persistent filters
 */
function updateStickyPLPComponents() {
    const animationBuffer = 16; // 16px = 1rem, buffer prevents content from showing between header and filter bar during animation
    if ($(window).scrollTop() > 0) {
        $('.js-filter-bar').hcSticky('update', {
            top: $siteHeader.outerHeight() - animationBuffer
        });
    }
}

/**
 * Initialize sticky components on PLP for persistent filters
 */
function initStickyPLPComponents() {
    $('.js-filter-bar').hcSticky({
        stickTo: '.js-plp-sticky-row'
    });

    document.addEventListener('scroll', updateStickyPLPComponents, { passive: true });
    $(window).on('widthResize', updateStickyPLPComponents);
}

module.exports = {
    initStickyPLPComponents: initStickyPLPComponents,
    destroyStickyPLPComponents: destroyStickyPLPComponents
};

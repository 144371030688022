/**
 * This file replaces "plugin_productcompare/cartridge/client/default/js/product/compare.js".
 */

'use strict';

/**
 * Global constants.
 */
/* global LazyLoad */
/* global _satellite */
const $compareBar = $('.js-compare-bar');
const $compareBarSlots = $compareBar.find('.js-compare-bar-slots');
const $compareBarForm = $compareBar.find('form');
const $compareBarTitle = $compareBar.find('.js-compare-bar-title');
const $compareButton = $compareBar.find('.js-btn-compare');
const compareHeaderText = $compareBar.data('header-text');
const compareSlotText = $compareBar.data('slot-text');
const compareModalTitle = $compareBar.data('modal-title');
const currentCategoryId = $('input.category-id').val();
const compareEnabled = !!$compareBar.length;

/**
 * Global modules.
 */
const breakpoints = require('../components/breakpoints');
const debounce = require('lodash/debounce');
const sessionSpecificLocalStorageHelper = require('../helper/sessionSpecificLocalStorageHelper');

/**
 * Retrieves an amount of maximum slots depending on the screen size.
 * Mobile - 2. Desktop - 3.
 * @return {number} - The maximum value.
 */
function getMaxSlots() {
    const maxSlots = breakpoints.isLowRes() ? 2 : 3;

    $compareBarForm.find('input[name="maxSlots"]').attr('value', maxSlots);

    return maxSlots;
}

/**
 * Global variables.
 */
let productsForComparison = [];
let maxSlots = getMaxSlots();
let compareState = null;
let $modal = null;

/**
 * Saves current state of compare feature to the session specific local storage.
 */
function saveCompareState() {
    compareState[currentCategoryId].products = productsForComparison;
    compareState[currentCategoryId].modal = !!$modal;
    compareState[currentCategoryId].highlight = $modal && $modal.hasClass('compare-modal--highlight');
    sessionSpecificLocalStorageHelper.setItem('compare', compareState);
}

/**
 * Refreshes the state of compare bar header.
 */
function refreshHeader() {
    $compareBarTitle.text(compareHeaderText.replace('{0}', productsForComparison.length).replace('{1}', maxSlots));
}

/**
 * Compiles the HTML for a single slot
 *
 * @param {string} pid - ID for product to compile
 * @param {string} colorId - Color ID for product to compile
 * @param {string} imgSrc - Image source for product to compile
 * @param {number} idx - Slot number (zero-based)
 * @return {string} - HTML for a single slot
 */
function compileSlot(pid, colorId, imgSrc, idx) {
    const name = 'pid' + idx;

    return `
        <div class="col">
            <div class="slot" data-pid="${pid}">
                <img src="${imgSrc}" />
                <div class="close">
                    <svg class="svgicon"><use href="#close"></use></svg>
                </div>
            </div>
            <input type="hidden" name="${name}" value="${pid}" />
            <input type="hidden" name="${name}_color" value="${colorId}" />
        </div>
    `;
}

/**
 * Draw and render the Compare Bar product slots.
 */
function redrawCompareSlots() {
    let html = productsForComparison.map(function (product, idx) {
        return compileSlot(product.pid, product.colorId, product.imgSrc, idx);
    }).join('');

    // Render empty slots
    if (productsForComparison.length < maxSlots) {
        const numAvailableSlots = maxSlots - productsForComparison.length;

        for (let i = 0; i < numAvailableSlots; i++) {
            html += `
                    <div class="col">
                        <div class="slot">
                            <button class="btn btn-link btn-compare-empty js-slot-empty">
                                <span>${compareSlotText}</span>
                            </button>
                        </div>
                    </div>
                    `;
        }
    }

    $compareBarSlots.empty().append(html);
}

/**
 * Enables/disables the Compare button.
 * Depends on amount of selected products.
 * Enabled - more than 1 product.
 * Disabled - less than 2 products.
 */
function setCompareButton() {
    if (productsForComparison.length < 2) {
        $compareButton.attr('disabled', true);
    } else {
        $compareButton.removeAttr('disabled');
    }
}

/**
 * Handles the selection of a product for comparison
 *
 * @param {string} pid - ID for product to compare
 * @param {string} colorId - Color ID for selected product
 * @param {string} imgSrc - Image URL for selected product
 */
function selectProduct(pid, colorId, imgSrc) {
    const pidIndex = productsForComparison.findIndex(function (product) {
        return product.pid === pid;
    });

    if (pidIndex !== -1) {
        productsForComparison[pidIndex].colorId = colorId;
        productsForComparison[pidIndex].imgSrc = imgSrc;
    } else if (productsForComparison.length < maxSlots) {
        productsForComparison.push({
            pid: pid,
            colorId: colorId,
            imgSrc: imgSrc
        });

        $('input#' + pid).prop('checked', true);

        if (productsForComparison.length === maxSlots) {
            // Disable all checkboxes which are not selected.
            $('.js-compare-checkbox input:not(:checked)').attr('disabled', true);
        }
    }

    redrawCompareSlots();
    setCompareButton();
    refreshHeader();
    saveCompareState();

    $compareBar.show();
}

/**
 * Handles the deselection of a product
 *
 * @param {string} pid - ID for product to compare
 */
function deselectProduct(pid) {
    const productsFiltered = productsForComparison.filter(function (product) {
        return product.pid !== pid;
    });

    if (productsFiltered.length < productsForComparison.length) {
        productsForComparison = productsFiltered;

        if (productsForComparison.length === 0) {
            $compareBar.removeClass('active').hide();
        }

        $('input#' + pid).prop('checked', false);

        // Enable all checkboxes
        $('.js-compare-checkbox input:not(:checked)').removeAttr('disabled');

        redrawCompareSlots();
        setCompareButton();
        refreshHeader();
        saveCompareState();
    }
}

/**
 * Clears the Compare Bar and hides it
 * @return {undefined}
 */
function clearCompareBar() {
    productsForComparison.forEach(function (product) {
        $(this).trigger('compare:deselected', { pid: product.pid });
    });
    productsForComparison = [];
    saveCompareState();

    // Reset all checkboxes
    $('.js-compare-checkbox input').prop('checked', false);
    $('.js-compare-checkbox input:not(:checked)').removeAttr('disabled');

    $compareBar.removeClass('active').hide();
}

/**
 * Initializes product images inside compare modal.
 */
function downloadLazyImages() {
    const lazyLoadInstance = new LazyLoad({
        elements_selector: '.lazy',
        use_native: true,
        load_delay: 200
    });

    if (lazyLoadInstance) {
        lazyLoadInstance.update();
    }
}

module.exports = {
    /**
     * Handles compare bar toggle click.
     * Handles empty slot click.
     * Shows or hides compare bar body toggling "active" class on the top container.
     */
    handleCompareBarToggleClick: function () {
        $compareBar.on('click', '.js-compare-bar-toggle, .js-slot-empty', function (e) {
            e.preventDefault();
            $compareBar.toggleClass('active');
        });
    },

    /**
     * Handles window resize event.
     * Refreshes the state of compare panel and related elements.
     */
    handleWindowResize: function () {
        if (compareEnabled) {
            $(window).on('resize', debounce(function () {
                maxSlots = getMaxSlots();

                while (productsForComparison.length > maxSlots) {
                    let product = productsForComparison.pop();
                    $('.js-compare-checkbox input#' + product.pid).prop('checked', false);
                }

                if (productsForComparison.length < maxSlots) {
                    // Enable all checkboxes which are not selected
                    $('.js-compare-checkbox input:not(:checked)').removeAttr('disabled');
                } else {
                    // Disable all checkboxes which are not selected
                    $('.js-compare-checkbox input:not(:checked)').attr('disabled', true);
                }

                redrawCompareSlots();
                setCompareButton();
                refreshHeader();
                saveCompareState();
            }, 300));
        }
    },

    /**
     * Handles the Compare checkbox click and changing the swatch.
     */
    handleCompareCheckboxEvents: function () {
        $('div.page').on('click swatch-changed', '.js-compare-checkbox input', function () {
            const $checkbox = $(this);
            const $productTile = $checkbox.closest('.product-tile');
            const $imagePrimarySelected = $productTile.find('.tile__image-container a.selected .tile__image--primary');
            const checked = $checkbox.is(':checked');
            const pid = $checkbox.attr('id').toString();
            const colorId = $productTile.find('.js-color-swatch.selected').data('variant-id').toString().replace(pid, '');
            const imgSrc = $imagePrimarySelected.data('src') || $imagePrimarySelected.prop('src');

            if (checked) {
                selectProduct(pid, colorId, imgSrc);
                $checkbox.trigger('compare:selected', { pid: pid });
            } else {
                deselectProduct(pid);
                $checkbox.trigger('compare:deselected', { pid: pid });
            }
        });
    },

    /**
     * Handles the Clear All link click.
     */
    handleClearAllClick: function () {
        $compareBar.on('click', '.js-btn-clear-all', function (e) {
            e.preventDefault();
            clearCompareBar();
        });
    },

    /**
     * Handles deselection of a product on the compare bar.
     */
    deselectProductOnCompareBar: function () {
        $compareBar.on('click', '.close', function (e) {
            e.preventDefault();
            const $button = $(this);
            const pid = $button.closest('.slot').data('pid').toString();
            deselectProduct(pid);
            $button.trigger('compare:deselected', { pid: pid });
        });
    },

    /**
     * Handles the submitting event of the compare bar form.
     */
    handleCompareBarFormSubmit: function () {
        $compareBarForm.on('submit', function (eSubmit) {
            eSubmit.preventDefault();

            $.spinner().start();

            $.ajax({
                url: $compareBarForm.attr('action'),
                type: 'GET',
                dataType: 'html',
                data: $compareBarForm.serialize()
            }).done(function (htmlResponse) {
                if ($modal) {
                    $modal.find('.modal-body').html(htmlResponse);

                    downloadLazyImages();

                    $.spinner().stop();
                } else {
                    const isModalHighlight = $compareBarForm.find('input[name="highlightDifferences"]').attr('value') === 'true';

                    $modal = $.modal({
                        content: htmlResponse,
                        title: compareModalTitle,
                        modalSizeClass: 'modal-xl',
                        customClasses: `compare-modal js-compare-modal ${isModalHighlight ? 'compare-modal--highlight' : ''}`
                    })
                    .one('shown.bs.modal', function () {
                        // native modal functionality adds additional space
                        // it can not be overlapped by CSS
                        // should be removed by JS
                        $modal.css('padding-right', '');

                        downloadLazyImages();
                        saveCompareState();
                        $compareBar.removeClass('active').hide();
                        $.spinner().stop();

                        if (typeof _satellite !== 'undefined') {
                            _satellite.track('productCompare', {
                                pageName: 'product compare',
                                pageType: 'product compare',
                                productsOnPage: productsForComparison.map(function (product) {
                                    return product.pid;
                                })
                            });
                        }
                    })
                    .on('hidden.bs.modal', function () {
                        $modal.remove();
                        $modal = null;
                        saveCompareState();

                        if (productsForComparison.length) {
                            $compareBar.show();
                        }
                    })
                    .on('click', '.js-slot-empty', function (eClick) {
                        eClick.preventDefault();
                        $modal.modal('hide');
                    })
                    .on('click', '.js-btn-product-badge', function (eClick) {
                        eClick.preventDefault();
                        const $button = $(this);
                        const pid = $button.closest('.product').data('pid').toString();

                        deselectProduct(pid);
                        $button.trigger('compare:deselected', { pid: pid });

                        if (productsForComparison.length === 0) {
                            $modal.modal('hide');
                        } else {
                            $compareBarForm.trigger('submit');
                        }
                    })
                    .on('click', '.js-color-swatch', function (eClick) {
                        eClick.preventDefault();
                        const $swatch = $(this);
                        const $productTile = $swatch.closest('.product');
                        const pid = $productTile.data('pid').toString();

                        // Updating "Shop Now" button in the compare modal
                        $(`.js-btn-compare-shop[data-pid="${pid}"]`).attr('href', $swatch.attr('href'));

                        // Finding the same product tile on the page
                        const $productTilePage = $(`.page .product[data-pid="${pid}"]`);
                        const $productSwatchPage = $productTilePage.find(`.js-color-swatch[data-variant-id="${$swatch.data('variant-id')}"]`);
                        $productSwatchPage.trigger('click');
                    })
                    .on('click', '.js-toggle-switch-input', function () {
                        $modal.toggleClass('compare-modal--highlight');
                        $compareBarForm.find('input[name="highlightDifferences"]').attr('value', $modal.hasClass('compare-modal--highlight'));
                        saveCompareState();
                    });
                }
            });
        });
    },

    /**
     * Initializes and restores the state of compare feature for the current category.
     * It waits until all the product tiles will be loaded, only the first time.
     */
    initialize: function () {
        if (compareEnabled) {
            $(document).one('search:lazyLoadSuccess', function () {
                compareState = sessionSpecificLocalStorageHelper.getItem('compare') || {};

                if (!compareState[currentCategoryId]) {
                    compareState[currentCategoryId] = {};
                    saveCompareState();
                }

                const productsRestored = compareState[currentCategoryId].products;
                const isModalActiveRestored = compareState[currentCategoryId].modal;
                const isModalHighlightRestored = compareState[currentCategoryId].highlight;

                productsRestored.forEach(function (product) {
                    const $productTile = $(`.product[data-pid="${product.pid}"]`);
                    const $productSwatch = $productTile.find(`.js-color-swatch[data-variant-id="${product.pid}${product.colorId}"]`);
                    const $compareCheckbox = $productTile.find('.js-compare-checkbox input');

                    if ($productTile.length && $productSwatch.length && $compareCheckbox.length) {
                        $productSwatch.trigger('click');
                        $compareCheckbox.trigger('click');
                    }
                });

                if (isModalActiveRestored && productsForComparison.length > 1) {
                    $compareBarForm.find('input[name="highlightDifferences"]').attr('value', isModalHighlightRestored);
                    $compareBarForm.trigger('submit');
                }
            });
        }
    }
};
